import {TableBase} from "./table_base";

class DriverchecksPointsTable extends TableBase {
    constructor() {
        super('driverchecks_points');
    }

    setup() {
        if(!app.DASHBOARD_CORE.TABLE_DATA[this.GRIDSTACK_NAME]) {
            return;
        }

        try {
            this.drawDriverCheckPoints();
        } catch(err) {
            console.warn(`Failed to setup table ${this.GRIDSTACK_NAME}.`, err);
        }
    }

    update() {
        if(!app.DASHBOARD_CORE.TABLE_DATA[this.GRIDSTACK_NAME]) {
            return;
        }

        try {
            this.drawDriverCheckPoints();
        } catch(err) {
            console.warn(`Failed to update table ${this.GRIDSTACK_NAME}.`, err);
        }
    }

    drawDriverCheckPoints() {
        app.DOM.gridstack.filter(`#${this.GRIDSTACK_NAME}`).find('.loading').hide();
        const data = app.DASHBOARD_CORE.TABLE_DATA[this.GRIDSTACK_NAME];
        const $dc = app.DOM.gridstack.filter('#driverchecks_points');
        if(!data) {
            $dc.find('.gridstack-controls .fa-times').trigger('click');
            return;
        }

        let $points = $dc.find('span.val'),
            index = 0;
        // loop through points and update dom
        $.each(data, (k, v) => {
            if(app.OPTIONS.countUp && $.isNumeric(v)) {
                $points.eq(index).countup({
                    endVal: v,
                    duration: 1.5
                });
            } else {
                $points.eq(index).text(v);
            }

            index++;
        });
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new DriverchecksPointsTable();
    } else {
        console.warn('DashboardCore not initalized.');
    }
});